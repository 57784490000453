<template>
    <div class="product">
        <figure class="product__badge">
            <figcaption>{{ product.productName }}</figcaption>
            <picture>
                <source type="image/svg+xml" :srcset="product | svgURL" />
                <img
                    :src="product | pngURL"
                    class="district-products-img"
                    :alt="product.productName"
                />
            </picture>
        </figure>
    </div>
</template>

<script>
export default {
    name: 'ProductLogo',
    filters: {
        pngURL: function (value) {
            if (value.productApp === 'allthedata') {
                try {
                    return require(`@/assets/product_logos/${value.productName}.png`)
                } catch {}
            }
            // school city products only have one logo but multiple applications.
            // to cut down on loading multiple images, just return the same image for anything that says school city.
            if (value.productApp.startsWith('schoolcity')) {
                try {
                    return require(`@/assets/product_logos/schoolcity.png`)
                } catch {}
            }
            try {
                return require(`@/assets/product_logos/${value.productApp}.png`)
            } catch {
                console.warn(`missing asset for ${value.productApp}.png`)
                return require(`@/assets/IE_logo_mark.png`)
            }
        },
        svgURL: function (value) {
            if (value.productApp === 'allthedata') {
                try {
                    return require(`@/assets/product_logos/${value.productName}.svg`)
                } catch {}
            }
            // school city products only have one logo but multiple applications.
            // to cut down on loading multiple images, just return the same image for anything that says school city.
            if (value.productApp.startsWith('schoolcity')) {
                try {
                    return require(`@/assets/product_logos/schoolcity.svg`)
                } catch {}
            }
            try {
                return require(`@/assets/product_logos/${value.productApp}.svg`)
            } catch {
                console.warn(`missing asset for ${value.productApp}.svg`)
                return require(`@/assets/IE_logo_text.svg`)
            }
        },
    },
    props: {
        product: {
            type: Object,
            default: () => ({
                productName: 'Default Name',
                productApp: 'default_app',
            }),
        },
    },
}
</script>

<style lang="scss" scoped>
.product .product__badge {
    img {
        height: 60px;
    }
    source {
        height: 60px;
    }
    figcaption {
        //This should be proxima nova but I think this is the only spot where the font is used.
        //not sure if that was on purpose.

        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0px;
        text-align: center;
        margin: 16px 0;
    }
    text-align: center;
}
</style>
